<template>
    <b-modal :title="$t('general.details')" v-model="modalActive" no-close-on-backdrop hide-footer size="xl">
        <CustomTable
            :fields="statusColumnDefs"
            :items="item.returnable_packaging"
            :showTableOptions="false"
        />
    </b-modal>
</template>
<script>
    import {BModal} from 'bootstrap-vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {
            BModal,
            CustomTable
        },
        data() {
            return {
                modalActive: false,
                item: []
            }
        },
        computed: {
            statusColumnDefs() {
                return [
                    {
                        key: 'name',
                        label: this.$t('keys.returnable_packaging_type'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'price_per_unit',
                        label: this.$t('table_fields.price_per_packaging'),
                        permissions: true,
                        formatter: Formaters.formatPrice
                    },
                    {
                        key: 'quantity',
                        label: this.$t('table_fields.quantity'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatQuantity
                    }
                ]
            }
        },
        methods: {
            async open(item) {
                this.item = item
                this.modalActive = true
            }
        }
    }
</script>