<template>
    <div>
        <b-card>
            <b-card-header>
                <b-card-title>{{$t('menu.subcategory.returnable_packaging_issues_and_returns')}}</b-card-title>
            </b-card-header>
            <b-card-body class="statistics-body">
                <div>
                    <CustomTable :fields="statusColumnDefs" api-url="api/client/v1/returnable_packaging/issues_and_returns" ref="returnablePackagingStatus">
                        <template #actions="{item}">
                            <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewDetails(item)">
                                {{ $t('general.details') }}
                            </BButton>
                        </template>
                    </CustomTable>
                    <ReturnablePackagingIssuesAndReturnsModal ref="ReturnablePackagingIssuesAndReturnsModal"/>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
    import CustomTable from '@/components/CustomTable.vue'
    import ReturnablePackagingIssuesAndReturnsModal from '@/views/ReturnablePackagingIssuesAndReturns/components/ReturnablePackagingIssuesAndReturnsModal.vue'

    import {
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BButton
    } from 'bootstrap-vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {
            BCardHeader,
            BCardTitle,
            BCard,
            BCardBody,
            BButton,
            CustomTable,
            ReturnablePackagingIssuesAndReturnsModal
        },
        data() {
            return {
                dataLoaded: false
            }
        },
        computed: {
            statusColumnDefs() {
                return [
                    {
                        key: 'type',
                        label: this.$t('keys.returnable_packaging.event_type'),
                        filter: true,
                        filterType: 'select',
                        filterParams: [
                            {value: 'returned_returnable_packaging', text: this.$t('keys.returnable_packaging.returned')},
                            {value: 'issued_returnable_packaging', text: this.$t('keys.returnable_packaging.issued')}
                        ],
                        filterParamsName: 'text',
                        filterKey: 'type',
                        reduce: ele => ele.id,
                        permissions: true,
                        formatter: this.formatType,
                        minWidth: 120
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('keys.returnable_packaging.event_date'),
                        filter: true,
                        filterType: 'date',
                        permissions: true,
                        formatter: Formaters.formatDate
                    },
                    {
                        key: 'cash_register',
                        label: this.$t('keys.returnable_packaging.cash_register'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'cash_register.w',
                        permissions: true
                    },
                    {
                        key: 'employee',
                        label: this.$t('keys.returnable_packaging.employee'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'employee.w',
                        permissions: true
                    },
                    {
                        key: 'returnable_packagings_types_count',
                        label: this.$t('keys.returnable_packaging.number_of_types'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'returnable_packagings_count',
                        label: this.$t('keys.returnable_packaging.total_number_of_packaging'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatQuantity
                    },
                    {
                        key: 'total_price',
                        label: this.$t('keys.returnable_packaging.value'),
                        filter: false,
                        permissions: true,
                        formatter: Formaters.formatPrice
                    },
                    {
                        key: 'payment_types',
                        label: this.$t('keys.returnable_packaging.payment_type'),
                        filter: false,
                        permissions: true,
                        formatter: this.formatPaymentTypes
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true,
                        disableSelect: true
                    }
                ]
            }
        },
        methods: {
            viewDetails(item) {
                this.$refs.ReturnablePackagingIssuesAndReturnsModal.open(item)
            },
            formatType(item) {
                return item === 'returned_returnable_packaging' ? this.$t('keys.returnable_packaging.returned') : this.$t('keys.returnable_packaging.issued')
            },
            formatPaymentTypes(item) {
                return [...new Set(item)].join(', ')
            }
        }
    }
</script>